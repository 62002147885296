import type { PaginateResult } from "mongoose"
import type { Organization } from "./organization.store"
import type { AssetTag } from "./assetTag.store"

export const useSharedStore = defineStore("shared", () => {
  /**
   * Gets all organizations that share data with the currently logged in organizations
   */
  const hasSharedAssets = async (): Promise<boolean> => {
    const { data } = await useSamApi<{ hasSharedAssets: boolean }>("/shared")
    return data.hasSharedAssets
  }

  /**
   * Gets all shared assets with the currently logged in organization unpaginated
   */
  const getAssets = async (queryParameters: Record<string, unknown> = {}): Promise<Array<Asset>> => {
    return useCrudMethods<Asset>("/shared/assets").readItems({ pagination: false, ...queryParameters })
  }

  /**
   * Gets all shared assets with the currently logged in organization paginated
   */
  const getAssetsByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Asset>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Asset>>(`/shared/assets?${query}`)
    return data
  }

  const getAsset = async (assetId: string): Promise<Asset> => {
    const { data } = await useSamApi<Asset>(`/shared/assets/${assetId}`)
    return data
  }

  const getAssetComponentGroups = async (assetId: string): Promise<Array<AssetComponentGroup>> => {
    const { data } = await useSamApi<Array<AssetComponentGroup>>(`/shared/assets/${assetId}/groups`)
    return data
  }

  const getAssetRelations = async (assetId: string): Promise<Array<PopulatedAssetRelation>> => {
    try {
      const { data } = await useSamApi<Array<PopulatedAssetRelation>>(`/shared/assets/${assetId}/relations`)
      return data
    }
    catch (e) {
      console.error(e)
      return []
    }
  }

  const getAssetImages = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/shared/assets/${assetId}/images`)
    return data
  }

  const getAssetDocuments = async (assetId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/shared/assets/${assetId}/documents`)
    return data
  }

  const getAssetCategory = async (assetId: string): Promise<AssetCategory> => {
    const { data } = await useSamApi<AssetCategory>(`/shared/assets/${assetId}/category`)
    return data
  }

  const getAssetTags = async (assetId: string): Promise<Array<AssetTag>> => {
    const { data } = await useSamApi<Array<AssetTag>>(`/shared/assets/${assetId}/tags`)
    return data
  }

  const getAssetExternalKeys = async (assetId: string): Promise<Array<PopulatedExternalKey>> => {
    const { data } = await useSamApi<Array<PopulatedExternalKey>>(`/shared/assets/${assetId}/external-keys`)
    return data
  }

  const generateAssetPdf = async (assetId: string, options?: GenerateAssetPdfOptions) => {
    const searchParams = getUrlSearchParams(options)

    const { data: pdf } = await useSamApi<Blob>(`/shared/assets/${assetId}/pdf?${searchParams}`, {
      headers: { "Accept": "*/*" },
      method: "POST",
    })

    return URL.createObjectURL(pdf)
  }

  const linkAssetToOrganization = async (assetId: string, organizationId: string): Promise<void> => {
    await useSamApi(`/shared/assets`, { method: "POST", body: JSON.stringify({ assetId, organizationId }) })
  }

  const unlinkAssetFromOrganization = async (assetId: string, organizationId?: string): Promise<void> => {
    await useSamApi(`/shared/assets/${assetId}?organizationId=${organizationId}`, { method: "DELETE" })
  }

  const getAssetSharedOrganizations = async (assetId: string): Promise<Array<Organization>> => {
    const { data } = await useSamApi<Array<Organization>>(`/shared/assets/${assetId}/organizations`)
    return data
  }

  const getTicketsByPage = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Ticket>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<Ticket>>(`/shared/tickets?${query}`)
    return data
  }

  const getAction = async (actionId: string): Promise<Action> => {
    const { data } = await useSamApi<Action>(`/shared/tickets/actions/${actionId}`)
    return data
  }

  const getMalfunction = async (malfunctionId: string): Promise<Malfunction> => {
    const { data } = await useSamApi<Malfunction>(`/shared/tickets/malfunctions/${malfunctionId}`)
    return data
  }

  const getInspection = async (inspectionId: string): Promise<Inspection> => {
    const { data } = await useSamApi<Inspection>(`/shared/tickets/inspections/${inspectionId}`)
    return data
  }

  const getSharedInspectionValues = async (inspectionId: string): Promise<Array<InspectionValue>> => {
    const { data } = await useSamApi<Array<InspectionValue>>(`/shared/tickets/inspections/${inspectionId}/values`)
    return data
  }

  const getTicketFiles = async (ticketId: string): Promise<Array<FileData>> => {
    const { data } = await useSamApi<Array<FileData>>(`/shared/tickets/${ticketId}/files`)
    return data
  }

  const generateTicketPdf = async (ticketId: string, options?: GenerateTicketPdfOptions) => {
    const searchParams = getUrlSearchParams(options)

    const { data: pdf } = await useSamApi<Blob>(`/shared/tickets/${ticketId}/pdf?${searchParams}`, {
      headers: { "Accept": "*/*" },
      method: "POST",
    })

    return URL.createObjectURL(pdf)
  }

  const getSharedOrganizations = async (): Promise<Array<Organization>> => {
    const { data } = await useSamApi<Array<Organization>>(`/shared/organizations`)
    return data
  }

  return {
    hasSharedAssets,

    getAssets,
    getAssetsByPage,
    getAsset,
    getAssetComponentGroups,
    getAssetRelations,
    getAssetImages,
    getAssetDocuments,
    getAssetCategory,
    getAssetTags,
    getAssetExternalKeys,

    generateAssetPdf,
    linkAssetToOrganization,
    unlinkAssetFromOrganization,
    getAssetSharedOrganizations,

    getTicketsByPage,

    getAction,
    getMalfunction,
    getInspection,

    getSharedInspectionValues,
    getTicketFiles,
    generateTicketPdf,

    getSharedOrganizations,
  }
})